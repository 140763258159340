import { FileType } from './attachment';
import { FormatIds } from './reactIntl';

export enum AssetAttachmentSource {
	crack = 'crack',
	sensor = 'sensor',
	system = 'system',
	scan_overlay = 'scan_overlay',
}

// images that should not appear under 'media' tab
export const NON_MEDIA_IMAGE_SOURCE: string[] = [
	AssetAttachmentSource.crack,
	AssetAttachmentSource.sensor,
	AssetAttachmentSource.scan_overlay,
];

export interface AssetAttributes {
	rotation?: number;
	annotations?: any[];
	sketch?: boolean;
	source?: AssetAttachmentSource;
	photo?: boolean;
	photo360?: boolean;
}

export interface Asset extends FileType {
	// use clientCreatedAt & clientUpdatedAt for display
	created: number;
	updated: number;
	clientCreatedAt: number;
	clientUpdatedAt: number;
	createdBy: number;
	fileID: string;
	folderID?: string;
	tags: number[];
	fileName: string;
	rasterizedURL?: string;
	category: ProjectAssetCategory;
	attributes?: AssetAttributes;
	orderNo: number;
}

export interface Folder {
	id: string;
	name: string;
	projectID: string;
	assetCount?: number;
	createdBy: number;
}

export interface UploadingAsset {
	lastModified: number;
	name: string;
	size: number;
	preview: string;
	progress: number;
	folderID?: string;
}

export interface ProgressingAsset {
	id: string;
	name: string;
	progress: number;
}

export const ROOT_FOLDER_ID = 'root';

export interface Assets {
	items: { [key: string]: Asset };
	folders: { [key: string]: Folder };
	itemIds: { [key: string]: string[] };
	folderIds: string[];
	uploadingAssets: { [key: string]: UploadingAsset };
	projectID: string;
	rootAssetCount: number;
}

export interface FileInfo {
	created: number;
	id: string;
	name: string;
	processedBy: string;
	size: number;
	status: number;
	tag: number;
	thumbnail: string;
	updated: number;
}

export interface Directory {
	projectID: string;
	folderID?: string;
	recursive?: boolean;
}

export enum ProjectAssetCategory {
	drawing = 0,
	document = 1,
	photo = 2,
	report = 5,
	twoDView = 6,
}

export enum AssetCategory {
	drawing = 'drawing',
	photo = 'photo',
	document = 'document',
	report = 'report',
	twoDView = 'twoDView',
	checklist = 'checklist',
}

export const ASSET_CATEGORY_FORMAT_ID: Partial<Record<AssetCategory | 'model', FormatIds>> = {
	[AssetCategory.twoDView]: 'App.Count.TwoDView',
	[AssetCategory.document]: 'App.Count.Document',
	[AssetCategory.photo]: 'App.No.Of.Photos',
	[AssetCategory.report]: 'App.Count.Report',
	[AssetCategory.drawing]: 'App.Count.Drawing',
	model: 'App.Count.ThreeDView',
};

// ProjectAssetCategory and AssetCategory mapping
export const ProjectAssetCategoryMapping: { [key in AssetCategory]: ProjectAssetCategory } = {
	[AssetCategory.drawing]: ProjectAssetCategory.drawing,
	[AssetCategory.photo]: ProjectAssetCategory.photo,
	[AssetCategory.document]: ProjectAssetCategory.document,
	[AssetCategory.report]: ProjectAssetCategory.report,
	[AssetCategory.twoDView]: ProjectAssetCategory.twoDView,
	[AssetCategory.checklist]: ProjectAssetCategory.document,
};

export const AssetCategoryMapping: Partial<Record<ProjectAssetCategory, AssetCategory>> = {
	[ProjectAssetCategory.photo]: AssetCategory.photo,
	[ProjectAssetCategory.document]: AssetCategory.document,
	[ProjectAssetCategory.drawing]: AssetCategory.drawing,
};

// The url constants used in routing is different from those specified in AssetCategory
// Hence, a separate mapping is needed
export const AssetCategoryRoutes: { [key in AssetCategory]: string } = {
	[AssetCategory.drawing]: 'drawings',
	[AssetCategory.photo]: 'photos',
	[AssetCategory.document]: 'documents',
	[AssetCategory.report]: 'reports',
	[AssetCategory.twoDView]: '2dView',
	[AssetCategory.checklist]: 'checklists',
};
