import { LanguageOptions } from '../../localization';
import { AnnouncementType } from '../../tstypes/appConfig';
import { LOCAL_STORAGE_DEMO_KEY } from '../../tstypes/companyDashboard';

interface SessionStorageItems {
	eagleIDState?: string;
	accessToken?: string;
	landingPathname?: string;
	landingQuery?: string;
	appVersion?: string;
	codeVerifier?: string;
	client?: string;
}

export interface UISettings {
	userID: number;
	recentColorCollection: string[];
	recentStrokeColorCollection: string[];
	spotMediaSortPreference: string;
}

export interface TutorialSettings {
	createFirstAsset: boolean;
	createSpotOnTwoDView: boolean;
	showCreateTwoDView: boolean;
}

type LocalStorageModalType = AnnouncementType | 'licenseExpired' | 'freePlanWelcome' | typeof LOCAL_STORAGE_DEMO_KEY;

interface LocalStorageItems {
	language?: LanguageOptions;
	devFeatureConfig?: string;
	injectToken?: 'true' | 'false';
	mapType?: 'satellite' | 'hybrid' | 'standard';
	UISettings?: UISettings;
	modalVisibility?: { [key in LocalStorageModalType]: boolean }; // to show modals only once
	tutorialSettings?: TutorialSettings;
	homePageEmail?: string; // to save 'email' query param from /home page
}

export function getSessionItem(key: keyof SessionStorageItems): string | null {
	return sessionStorage.getItem(key);
}

export function saveSessionItem(key: keyof SessionStorageItems, value: string) {
	sessionStorage.setItem(key, value);
}

export function removeSessionItem(key: keyof SessionStorageItems) {
	sessionStorage.removeItem(key);
}

export function getLocalItem(key: keyof LocalStorageItems): string | null {
	return localStorage.getItem(key);
}

export function saveLocalItem(key: keyof LocalStorageItems, value: string) {
	localStorage.setItem(key, value);
}

export function removeLocalItem(key: keyof LocalStorageItems) {
	localStorage.removeItem(key);
}

export const updateLocalStorageModalVisibility = (key: LocalStorageModalType) => {
	const localStorageConfig = getLocalItem('modalVisibility');
	const newConfig =
		localStorageConfig === null ? { [key]: false } : { ...JSON.parse(localStorageConfig), [key]: false };
	saveLocalItem('modalVisibility', JSON.stringify(newConfig));
};

export const getModalVisibility = (modalType: LocalStorageModalType) => {
	const localStorageConfig = getLocalItem('modalVisibility');
	if (localStorageConfig === null) {
		return true;
	}
	const parsedConfig = JSON.parse(localStorageConfig);
	return parsedConfig[modalType] ?? true;
};
